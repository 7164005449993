<template>
  <div class="live-feed-secondary-part-holder">
    <table>
      <thead>
        <!-- <tr>
          <th>{{ $t("text_username") }}</th>
          <th :class="who_page == 'main' ? '' : 'hidden'">
            {{ $t("deposit") }}
          </th>
          <th>{{ who_page == "main" ? $t("withdrawl") : $t("text_won") }}</th>
        </tr> -->
        <tr>
          <th>{{ $t("text_username") }}</th>
          <th>
            {{ $t("invest_text6") }}
          </th>
          <th>{{ $t("text_sell") }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="l in c_tableData" :key="l">
          <td>{{ l.userid }}</td>
          <td style="text-transform: UPPERCASE">{{ $t("cur") }} {{ l.win }}</td>
          <td style="text-transform: UPPERCASE">
            {{ $t("cur") }} {{ l.win2 }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
export default {
  props: { who_page: String },
  data() {
    return {
      isMobile: this.$Utils.isMobile(),
      tableData: [
        {
          userid: Math.ceil(Math.random() * 10000 + 10000) + "***",
          win: Math.ceil(Math.random() * 500 + 5),
          win2: Math.ceil(Math.random() * 500 + 5),
        },
        {
          userid: Math.ceil(Math.random() * 10000 + 10000) + "***",
          win: Math.ceil(Math.random() * 500 + 5),
          win2: Math.ceil(Math.random() * 500 + 5),
        },
        {
          userid: Math.ceil(Math.random() * 10000 + 10000) + "***",
          win: Math.ceil(Math.random() * 500 + 5),
          win2: Math.ceil(Math.random() * 500 + 5),
        },
        {
          userid: Math.ceil(Math.random() * 10000 + 10000) + "***",
          win: Math.ceil(Math.random() * 500 + 5),
          win2: Math.ceil(Math.random() * 500 + 5),
        },
        {
          userid: Math.ceil(Math.random() * 10000 + 10000) + "***",
          win: Math.ceil(Math.random() * 500 + 5),
          win2: Math.ceil(Math.random() * 500 + 5),
        },
        {
          userid: Math.ceil(Math.random() * 10000 + 10000) + "***",
          win: Math.ceil(Math.random() * 500 + 5),
          win2: Math.ceil(Math.random() * 500 + 5),
        },
        {
          userid: Math.ceil(Math.random() * 10000 + 10000) + "***",
          win: Math.ceil(Math.random() * 500 + 5),
          win2: Math.ceil(Math.random() * 500 + 5),
        },
      ],
    };
  },
  methods: {
    scroll() {
      setInterval(() => {
        var insert_data = [
          {
            userid: Math.ceil(Math.random() * 10000 + 10000) + "***",
            win: Math.ceil(Math.random() * 500 + 5),
            win2: Math.ceil(Math.random() * 500 + 5),
          },
        ];
        this.tableData.push(insert_data[0]);
        this.tableData.shift();
      }, 1000);
    },
  },
  mounted() {},
  created() {
    // this.scroll();
  },
  components: {},
  computed: {
    c_tableData() {
      if (this.isMobile != "pc端") {
        return this.tableData.slice(0, 2);
      } else return this.tableData;
    },
  },
};
</script>
