<template>
  <div>
    <div>
      <div class="row home_game_arrow home_game_casino">
        <div class="d-flex justify-content-between align-items-center">
          <div class="game_logo_img fw-bolder my-3">
            <div class="subtitle_line"></div>
            <span class="text_span"> {{ $t("text_immi").toUpperCase() }}</span>
          </div>
          <!-- <span
            @click="this.$router.push('/' + Id)"
            class="see-all-btn text_span"
            >{{ $t("text_seeall") }}</span
          > -->
        </div>

        <div class="wc_im_st">
          <table class="w-100">
            <thead>
              <tr>
                <th class="text-center">
                  {{ $t("text_company").toUpperCase() }}
                </th>
                <th class="text-center d-none d-lg-table-cell">
                  {{ $t("text_code").toUpperCase() }}
                </th>
                <th class="text-center d-none d-lg-table-cell">
                  {{ $t("text_rf").toUpperCase() }}
                </th>
                <th class="text-center">
                  {{ $t("text_price").toUpperCase() }}
                </th>
                <th class="text-center"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="l in stockList" :key="l">
                <td class="text-center">{{ l.cname }}</td>
                <td class="text-center d-none d-lg-table-cell">
                  {{ l.mpkidx }}
                </td>
                <td
                  style="gap: 5px"
                  class="text-center d-none justify-content-center no_btn_hover align-items-center position-relative d-lg-flex"
                  :class="
                    l.rate > 0
                      ? 'bg-t-red fw-bolder'
                      : l.rate < 0
                      ? 'bg-t-green fw-bolder'
                      : ''
                  "
                >
                  <div
                    class="position-absolute start-0 top-0 h-100 d-xl-block d-none"
                    :class="
                      l.rate > 0
                        ? 'bg-line-red'
                        : l.rate < 0
                        ? 'bg-line-green'
                        : ''
                    "
                  ></div>
                  <span class="text-nowrap"> {{ l.rate }} %</span>

                  <!-- <charview :tableData="tableData[k]" /> -->
                </td>
                <td class="text-center">
                  <img
                    src="@/assets/img/main/diamond.png"
                    style="width: 25px; height: 25px"
                    class="me-1"
                  />
                  <span>{{ l.payment }}</span>
                </td>
                <td class="text-center d-flex" style="gap: 5px">
                  <button
                    class="border-0 text-white bg-s-buy p-2 w-50 text-nowrap"
                    @click="click_detail('buy', l)"
                  >
                    <span>{{ $t("invest_text6") }}</span>
                  </button>
                  <button
                    class="border-0 text-white bg-s-sell p-2 w-50 text-nowrap"
                    @click="click_detail('sell', l)"
                  >
                    <span>{{ $t("text_sell") }}</span>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <invest
      v-if="flag_invest_page"
      :click_list="click_list"
      :action="action"
      @fun_flag_page="fun_flag_page"
    />
  </div>
</template>
<script>
import Cookies from "js-cookie";
import invest from "./invest.vue";
// import charview from "./char.vue";
export default {
  components: {
    invest,
    // charview,
  },
  data() {
    return {
      flag_invest_page: false,
      action: "",
      stockList: [],
      click_list: [],
      tableData: [
        [1320, 532, 901, 334, 1290, 730, 820],
        [60, 55, 80, 334, 250, 336, 503],
        [107, 99, 63, 28, 71, 106, 160],
        [88, 33, 62, 98, 107, 116, 138],
        [236, 500, 450, 800, 760, 210, 198],
        [60, 55, 80, 334, 250, 336, 503],
        [107, 99, 63, 28, 71, 106, 160],
        [138, 33, 62, 98, 107, 116, 88],
        [300, 360, 350, 295, 286, 210, 290],
      ],
      isMobile: this.$Utils.isMobile(),
    };
  },
  methods: {
    click_detail: function (action, e) {
      if (Cookies.get("code") == null || Cookies.get("code") == "") {
        this.$store.commit("islogin", true);
      } else {
        this.action = action;
        this.click_list = e;
        this.flag_invest_page = true;
      }
    },
    fun_flag_page: function (e) {
      this.flag_invest_page = e;
    },
    betstock_fun: function () {
      let param = {
        action: "betstock_item",
        body: {
          uid: localStorage.getItem("uid"),
        },
      };
      this.$store.dispatch("getpost", param).then((res) => {
        var status = res.status;
        if (status == "success") {
          this.stockList = res.data;
        }
      });
    },
  },
  watch: {},
  computed: {
    f_isMobile() {
      if (this.isMobile != "pc端") {
        return true;
      } else return false;
    },
  },
  mounted() {},
  created() {
    this.betstock_fun();
  },
};
</script>
