<template>
  <div>
    <div class="finance_page">
      <div class="finance_card">
        <div class="finance_close" @click="this.$emit('fun_flag_page', false)">
          ×
        </div>
        <div
          class="finance_title"
          :class="action.toLowerCase() == 'sell' ? 'bg-red' : ''"
        >
          {{ $t("text_game_finance") }}
        </div>

        <div class="finance_main">
          <div class="finance_deposit" style="margin-top: 10px">
            <p class="mb-1">
              {{ $t("finance_text1") }}：{{ click_list.cname }}
            </p>
            <p>{{ click_list.description }}</p>

            <div>
              <p class="mb-2">
                {{
                  action.toLowerCase() == "sell"
                    ? $t("text_sell_amount")
                    : $t("finance_text4")
                }}
              </p>
              <input type="text" class="finance_text" v-model="payment" />
              <p v-show="flag_pay_error">
                <span style="color: Red">{{ $t("error_tips") }}</span>
              </p>
            </div>

            <p style="margin-top: 1rem">
              {{ $t("finance_text2") }}：{{ click_list.payment }}
            </p>
            <div v-if="action.toLowerCase() != 'sell'">
              <p>{{ $t("finance_text5") }}：{{ click_list.rate }}%</p>
              <p>
                {{ $t("finance_text6") }}：{{
                  parseFloat((click_list.payment * payment).toFixed(2))
                }}&nbsp;{{ $t("cur") }}
              </p>
              <p>
                {{ $t("finance_text7") }}：{{
                  parseFloat(
                    (
                      click_list.payment *
                      payment *
                      click_list.rate *
                      0.01
                    ).toFixed(2)
                  )
                }}&nbsp;{{ $t("cur") }}
              </p>
              <p class="mb-2">
                {{ $t("finance_text8") }}：{{
                  parseFloat(
                    (
                      (click_list.payment * payment * click_list.rate * 0.01) /
                      12
                    ).toFixed(2)
                  )
                }}&nbsp;{{ $t("cur") }}
              </p>
              <p>{{ $t("finance_text9") }}</p>

              <p v-if="action.toLowerCase() != 'sell'">
                {{ $t("text_tran_fee") }}：{{ click_list.fee * 100 }}%
              </p>
            </div>
            <div class="col-10 text-white mx-auto my-3">
              <button
                @click="buy_etf_item"
                class="border-0 text-white p-2 w-100"
                :disabled="flag_pay_error"
                :style="
                  flag_pay_error
                    ? 'cursor: no-drop;background-color: #CCC;'
                    : ''
                "
                :class="
                  action.toLowerCase() == 'sell'
                    ? 'bg_red_btn'
                    : 'bg_light_green_btn'
                "
              >
                <span>{{
                  action.toLowerCase() == "sell"
                    ? $t("text_sell")
                    : $t("invest_text6")
                }}</span>
              </button>
              <p
                class="text-black text-center mt-2"
                v-if="action.toLowerCase() != 'sell'"
              >
                {{ $t("finance_text10") }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <withdraw_pw
      v-if="flag_pw_page"
      :click_list="click_list"
      :payment="payment"
      :action="action"
      :fina_inve="'etf'"
      @fun_pw_page="fun_pw_page"
    />
  </div>
</template>
<script>
import withdraw_pw from "./withdraw_pw.vue";
export default {
  props: { click_list: Array, action: String },
  data() {
    return {
      flag_pay_error: false,
      list: [],
      payment: 0,
      list_index: 6,
      flag_pw_page: false,
    };
  },
  components: { withdraw_pw },
  methods: {
    etf_item: function () {
      let param = {
        action: "etf_item",
        body: {
          uid: localStorage.getItem("uid"),
        },
      };
      this.$store.dispatch("getpost", param).then((res) => {
        var status = res.status;
        if (status == "success") {
          this.list = res.data;
        }
      });
    },
    buy_etf_item: function () {
      if (this.payment != 0 && this.payment != "" && this.payment != null) {
        this.flag_pw_page = true;
      } else this.flag_pay_error = true;
    },
    more: function () {
      this.list_index = this.list.length;
    },
    fun_pw_page: function (e, e1) {
      this.flag_pw_page = e;
      if (e1 != null && e1 != "") this.$emit("fun_flag_page", false);
    },
  },
  mounted() {},
  created() {
    // this.etf_item();
  },
  watch: {
    payment: function (e) {
      if (!Number(e)) {
        this.flag_pay_error = true;
      } else this.flag_pay_error = false;
    },
  },
};
</script>
