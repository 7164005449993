<template>
  <div class="footer">
    <div class="d-flex row mx-auto p-3 mb-0" style="max-width: 1200px">
      <div class="col-6 col-xl-3">
        <div class="flex-column d-inline-flex">
          <div class="" @click="this.$router.push('/gamestock')">
            <span class="footer_subtitle">{{ $t("text_stock2") }}</span>
          </div>
          <div class="" @click="this.$router.push('/sportstock')">
            <span class="footer_subtitle">{{ $t("text_stock3") }}</span>
          </div>
          <div class="" @click="this.$router.push('/hotstock')">
            <span class="footer_subtitle">{{ $t("text_stock4") }}</span>
          </div>
          <div class="" @click="this.$router.push('/marketstock')">
            <span class="footer_subtitle">{{ $t("text_stock5") }}</span>
          </div>
          <div class="" @click="this.$router.push('/startupstock')">
            <span class="footer_subtitle">{{ $t("text_stock6") }}</span>
          </div>
          <div class="" @click="this.$router.push('/etf')">
            <span class="footer_title">{{ $t("text_stock1") }}</span>
          </div>
        </div>
      </div>
      <div class="col-6 col-xl-3">
        <div class="flex-column d-inline-flex">
          <div class="">
            <span class="footer_title">{{ $t("footer_help") }}</span>
          </div>
          <!-- <div class="" @click="this.$router.push('/help/K')">
            <span class="footer_subtitle">{{ $t("footer_k") }}</span>
          </div> -->
          <!-- <div class="" @click="this.$router.push('/help/P')">
            <span class="footer_subtitle">{{ $t("footer_p") }}</span>
          </div> -->
          <!-- <div class="" @click="this.$router.push('/help/R')">
            <span class="footer_subtitle">{{ $t("footer_r") }}</span>
          </div> -->
          <!-- <div class="" @click="this.$router.push('/help/T')">
            <span class="footer_subtitle">{{ $t("footer_t") }}</span>
          </div> -->
          <div class="" @click="this.$router.push('/help/F')">
            <span class="footer_subtitle">{{ $t("footer_f") }}</span>
          </div>
          <!-- <div class="" @click="this.$router.push('/help/E')">
            <span class="footer_subtitle">{{ $t("footer_e") }}</span>
          </div> -->
        </div>
      </div>
      <div class="col-12 col-xl-3 mt-3 mt-xl-0">
        <div class="flex-column d-inline-flex">
          <div class="">
            <div class="d-flex flex-wrap" style="width: 80%">
              <img
                class="footer_right_img"
                src="@/assets/img/footer/mga.png"
                alt=""
              /><img
                class="footer_right_img"
                src="@/assets/img/footer/pagcor.png"
                alt=""
              /><img
                class="footer_right_img"
                src="@/assets/img/footer/gambling_commission.png"
                alt=""
              /><img
                class="footer_right_img"
                src="@/assets/img/footer/gaming_curacao.png"
                alt=""
              />
            </div>
          </div>
          <div class="">
            <div class="flex-row">
              <div class="">
                <span class="footer_title">{{ $t("footer_a") }}</span>
              </div>
            </div>
          </div>
          <div class="">
            <span class="footer_subtitle" style="cursor: initial">{{
              $t("footer_a_content")
            }}</span>
          </div>
        </div>
      </div>
      <div class="col-12 col-xl-3 ps-xl-5 mt-3 mt-xl-0">
        <img :src="img_logo" alt="Logo" class="w-100 mw-50" />
        <p class="footer_subtitle text-center" style="color: #c3c3c3">
          © 2021-2024, Betstock. ALL Rights Reserved.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { main_logo_w } from "@/components/Img/img";
export default {
  name: "footertwo",
  data() {
    return {
      img_logo:
        localStorage.getItem("locale") == null ||
        localStorage.getItem("locale") == "" ||
        (localStorage.getItem("locale") != "en" &&
          localStorage.getItem("locale") != "cn")
          ? JSON.parse(JSON.stringify(main_logo_w))["en"]
          : JSON.parse(JSON.stringify(main_logo_w))[
              localStorage.getItem("locale")
            ],
    };
  },
  components: {},
  computed: {},
  methods: {},
  created() {},
  watch: {},
  mounted() {},
};
</script>
