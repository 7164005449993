<template>
  <div>
    <maintop1 />
    <maintop2 ref="getbalance_home" />
    <loginview v-if="islogin || isreg" />
    <!-- <mainsportview v-if="Id == 'main_sport'" /> -->
    <div class="container" id="home">
      <div class="row mb-5">
        <mainview v-if="Id == '' || Id == 'Login' || Id == 'Reg'" />
        <homestock
          v-if="
            Id == 'etf' ||
            Id == 'gamestock' ||
            Id == 'sportstock' ||
            Id == 'hotstock' ||
            Id == 'marketstock' ||
            Id == 'startupstock'
          "
        />
        <promotion v-if="Id == 'promo'" />
        <!-- <vipintro v-if="Id == 'VIP_intro'" /> -->
        <!-- <share v-if="Id == 'share'" /> -->
        <home2 v-if="Id == 'ME'" />
        <home3 v-if="Id == 'BO'" />
        <homehelp v-if="Id == 'help'" />
        <!-- <member v-if="Id == 'VIP'" /> -->
      </div>
      <prewindow></prewindow>
    </div>

    <footertwo />
    <Footer />
    <newmessage ref="newmess"></newmessage>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Cookies from "js-cookie";
import maintop1 from "./Main_top1.vue";
import maintop2 from "./Main_top2.vue";
import mainview from "./main.vue";
import Footer from "./Home/Footer.vue";
import footertwo from "./Home/footer_two.vue";
import Prewindow from "./Home/prewindow.vue";
import loginview from "./Login.vue";
// import mainsportview from "./main_sport.vue";
import homestock from "./home_stock.vue";
import promotion from "./Promotion.vue";
// import vipintro from "./vip_introduce.vue";
// import share from "./share.vue";
import home2 from "./Home2.vue";
import home3 from "./Home3.vue";
import homehelp from "./footer_help.vue";
// import member from "./Member.vue";
import newmessage from "./new_Message.vue";

export default {
  name: "Home",
  data() {
    return {
      code: Cookies.get("code"),
    };
  },
  components: {
    maintop1,
    maintop2,
    mainview,
    Footer,
    footertwo,
    Prewindow,
    loginview,
    // mainsportview,
    homestock,
    promotion,
    // vipintro,
    // share,
    home2,
    home3,
    homehelp,
    // member,
    newmessage,
  },
  computed: {
    ...mapGetters(["watch_newWindowurl", "islogin", "isreg"]),
    Id: function () {
      if (this.$route.params.id != null) return this.$route.params.id;
      else return "";
    },
  },
  methods: {
    ...mapActions(["getBalance"]),
  },
  created() {
    if (this.Id == "Login" && (this.code == null || this.code == ""))
      this.$store.commit("islogin", true);
    else if (this.Id == "Reg" && (this.code == null || this.code == ""))
      this.$store.commit("isreg", true);
  },
  watch: {
    watch_newWindowurl: function (newval) {
      if (newval == "close") {
        this.getBalance();
        this.$refs.newmess.msg("30");
      }
    },
  },
  mounted() {},
};
</script>
