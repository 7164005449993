<template>
  <div>
    <div class="login-popup">
      <span class="popup-close-btn-login" @click="close_login()"
        ><svg
          xmlns="http://www.w3.org/2000/svg"
          width="13"
          height="13"
          viewBox="0 0 13 13"
        >
          <path
            fill="#8d999f"
            fill-rule="evenodd"
            d="M12.684 1.85c.211-.211.316-.467.316-.767s-.105-.555-.316-.767A1.045 1.045 0 0011.917 0c-.3 0-.556.105-.768.316L6.5 4.966 1.85.315A1.045 1.045 0 001.084 0c-.3 0-.555.105-.767.316A1.045 1.045 0 000 1.083c0 .3.105.556.316.768L4.966 6.5l-4.65 4.65a1.045 1.045 0 00-.316.767c0 .3.105.555.316.767.212.211.468.316.767.316.3 0 .556-.105.768-.316L6.5 8.034l4.65 4.65c.211.211.467.316.767.316s.555-.105.767-.316c.211-.212.316-.468.316-.767 0-.3-.105-.556-.316-.768L8.034 6.5l4.65-4.65z"
          ></path>
        </svg>
      </span>
      <div class="login-popup-logo">
        <img :src="img_logo" alt="Logo" style="width: 175px" />
        <h3></h3>
      </div>
      <form class="w-100" @submit="checkForm">
        <div class="login-popup-form-container">
          <div class="form-item">
            <input
              type="text"
              autocomplete="on"
              v-model="uid"
              tabindex="0"
              maxlength="10"
              minlength="4"
              required="required"
              :placeholder="$t('text_userid')"
            /><span class="form-item-label">{{ $t("text_userid") }}</span>
          </div>
          <div class="form-item">
            <input
              type="password"
              v-model="pwd"
              minlength="6"
              maxlength="12"
              required="required"
              :placeholder="$t('passwordTitle')"
            /><span class="form-item-label">{{ $t("passwordTitle") }}</span>
            <!-- <span class="form-show-hide-pw"></span> -->
          </div>
          <div class="form-item">
            <input
              type="text"
              v-model="captcha"
              maxlength="4"
              minlength="4"
              required="required"
              :placeholder="$t('text_Captcha')"
              ref="captcha"
            /><span class="form-item-label">{{ $t("text_Captcha") }}</span>
            <captchaview ref="captgetcode" />
          </div>
          <div class="login-popup-forgot-password">
            <p>
              <span @click="link('service')">{{ $t("forgetpwd") }}</span>
            </p>
          </div>
        </div>
        <div class="login-popup-footer">
          <!-- <button class="login-popup-action-btn" @click="checkForm()">
          {{ $t("login1") }}
        </button> -->
          <input
            type="submit"
            class="login-popup-action-btn"
            :value="$t('login1')"
          />
          <div class="login-popup-register-text">
            <p>
              {{ $t("text_not_reg")
              }}<span
                @click="
                  close_login();
                  open_login('isreg');
                "
              >
                &nbsp;{{ $t("register") }}</span
              >
            </p>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import Cookies from "js-cookie";
import { mapGetters } from "vuex";
import api from "../../components/http/api";
import captchaview from "../Home/captcha.vue";
import { main_logo_w } from "@/components/Img/img";

export default {
  name: "loginpart",
  data() {
    return {
      uid: "",
      pwd: "",
      img_logo:
        localStorage.getItem("locale") == null ||
        localStorage.getItem("locale") == "" ||
        (localStorage.getItem("locale") != "en" &&
          localStorage.getItem("locale") != "cn")
          ? JSON.parse(JSON.stringify(main_logo_w))["en"]
          : JSON.parse(JSON.stringify(main_logo_w))[
              localStorage.getItem("locale")
            ],
    };
  },
  watch: {
    pwd: function () {
      this.pwd = this.pwd.replace(/[^A-Za-z0-9]/g, "");
    },
    uid: function () {
      this.uid = this.uid.replace(/[^A-Za-z0-9]/g, "");
    },
  },
  async created() {
    await this.GetIP();
    if (localStorage.getItem("ip") != null) {
      if (localStorage.getItem("ip").length < 7) {
        this.$MSG.API_popmsg(
          "IP is error, Please try again or change another network",
          "",
          "error"
        );
      }
    }
  },
  mounted() {},
  methods: {
    async GetIP() {
      await api.getIP().then((res) => {
        localStorage.setItem("ip", res);
      });
    },
    checkForm: function (e) {
      e.preventDefault();
      if (this.checkcode()) {
        localStorage.setItem("device", this.$Utils.isMobile());
        let param = {
          action: "Login",
          body: {
            uid: this.uid,
            pwd: this.pwd,
            ip: localStorage.getItem("ip"),
            device: localStorage.getItem("device"),
            token: "",
            regdomain: document.location.origin,
          },
        };
        this.$store.dispatch("getpost", param).then((res) => {
          var status = res.status;
          if (status == "success") {
            Cookies.set("code", res.session_code);
            localStorage.setItem("uid", this.uid);
            localStorage.setItem("utype", res.utype);
            localStorage.setItem("big_logo", res.logo);
            this.$store.commit("flag_code", true);
            this.close_login();
            this.$router.push("/");
            // window.location.reload();
          } else {
            this.$MSG.API_popmsg(res.msg, "", "error");
          }
        });
      } else {
        this.$MSG.API_popmsg(this.$t("text_error_cap"), "", "error");
      }
    },
    checkcode: function () {
      return this.$refs.captgetcode.getdata() == this.captcha ? true : false;
    },
    link: function (e) {
      var url = "";
      switch (e) {
        case "service":
          url = this.$store.state.service.qq.qq;
          break;
        default:
          url = "";
          break;
      }
      window.open(url);
    },
    close_login: function () {
      this.$store.commit("islogin", false);
    },
    open_login: function (id) {
      this.$store.commit(id, true);
    },
  },
  computed: {
    ...mapGetters(["service", "flag_code"]),
  },
  components: { captchaview },
};
</script>
