<template>
  <!-- 最上面的nav -->
  <div class="header-wrapper-m fixed-header gb-logged-out">
    <nav
      class="container navigation-menu-wrapper d-flex align-items-center position-relative mx-auto my-0"
    >
      <div
        class="top-nav-container d-flex align-items-center position-absolute"
      >
        <!-- 未登入 -->
        <div
          class="top-nav-logged-out d-flex align-items-center position-relative"
          v-if="!flag_code"
        >
          <div class="login-register-header-form d-flex align-items-center">
            <button
              class="transparent-button btn_login"
              @click="open_login('islogin')"
            >
              {{ $t("login1") }}</button
            ><button
              class="transparent-button signup"
              @click="open_login('isreg')"
            >
              {{ $t("text_join") }}
            </button>
          </div>
          <div
            class="user-info-container browser-disable-text-selecting d-none d-xl-block"
            @click="show_nav()"
          >
            <div class="user-profile-image">t</div>
          </div>
        </div>
        <!-- 登入 -->
        <div class="top-nav-logged-in" v-else>
          <div class="header-balance-info">
            <div class="balance-in-header">
              <balanceview />
              <div class="available-balance-details hidden">
                <div>
                  <span>可提取余额:</span><span>{{ $t("cur") }} 0.00 </span>
                </div>
                <!-- <div>
                  <span>奖金余额:</span><span>{{ $t("cur") }} 0.00 </span>
                </div> -->
                <div class="withdraw-btn-wrapper">
                  <button
                    class="transparent-button transparent-button-active user-header-withdraw-btn"
                    @click="
                      $router.push({
                        path: '/ME/wallet',
                        query: { id: 'withdraw' },
                      })
                    "
                  >
                    {{ $t("withdrawl") }}
                  </button>
                </div>
              </div>
            </div>
            <button
              class="transparent-button transparent-button-active user-header-deposit-btn"
              @click="
                $router.push({ path: '/ME/wallet', query: { id: 'deposit' } })
              "
            >
              {{ $t("deposit") }}
            </button>
          </div>
          <div
            class="user-info-container browser-disable-text-selecting d-none d-lg-block"
            @click="show_nav()"
          >
            <div class="user-profile-image">t</div>
          </div>
        </div>
        <!-- 语言 -->
        <lang></lang>
      </div>
      <div class="logo-wrapper-m lcfc">
        <img
          v-if="!flag_code && this.isMobile == 'pc端'"
          :src="img_logo"
          class="top_head_img c-pointer d-none d-lg-block top_head_img_pic"
          @click="this.$router.push('/')"
        />
        <img
          v-else
          src="@/assets/gembet_logo_pic.png"
          class="top_head_img c-pointer d-block top_head_img_mob"
          @click="this.$router.push('/')"
        />
      </div>
      <div class="nav-menu-inline-container d-xl-inline-block d-none">
        <div class="nav-bar-contain-m browser-disable-text-selecting">
          <ul :class="!flag_code ? 'p-0' : ''">
            <li @click="this.$router.push('/gamestock')">
              <a :class="{ active: Id == 'gamestock' }"
                >{{ $t("text_stock2") }}
              </a>
            </li>
            <li @click="this.$router.push('/sportstock')">
              <a :class="{ active: Id == 'sportstock' }">{{
                $t("text_stock3")
              }}</a>
            </li>
            <li @click="this.$router.push('/hotstock')">
              <a :class="{ active: Id == 'hotstock' }">{{
                $t("text_stock4")
              }}</a>
            </li>

            <li @click="this.$router.push('/marketstock')">
              <a :class="{ active: Id == 'marketstock' }">{{
                $t("text_stock5")
              }}</a>
            </li>
            <li @click="this.$router.push('/startupstock')" v-if="resize">
              <a :class="{ active: Id == 'startupstock' }">{{
                $t("text_stock6")
              }}</a>
            </li>
            <li @click="this.$router.push('/etf')" v-if="resize">
              <a class="live-sports-navlink" :class="{ active: Id == 'etf' }">
                {{ $t("text_stock1") }}
                <!-- <svg
                  width="12"
                  height="8"
                  viewBox="0 0 12 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.41 0.589966L6 5.16997L10.59 0.589966L12 1.99997L6 7.99997L0 1.99997L1.41 0.589966Z"
                    fill="#F9F8F8"
                  ></path>
                </svg> -->
              </a>
            </li>

            <li v-if="!resize">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdownMenuLink"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                >{{ $t("text_more") }}
              </a>
              <ul
                class="dropdown-menu dropdown-maintop2"
                aria-labelledby="navbarDropdownMenuLink"
              >
                <!-- <li @click="this.$router.push('/marketstock')">
                  <a class="dropdown-item"> {{ $t("text_stock5") }}</a>
                </li> -->
                <li @click="this.$router.push('/startupstock')">
                  <a class="dropdown-item"> {{ $t("text_stock6") }}</a>
                </li>
                <li @click="this.$router.push('/etf')">
                  <a class="dropdown-item"> {{ $t("text_stock1") }}</a>
                </li>
              </ul>
            </li>
            <!-- <li @click="this.$router.push('/share')" v-if="flag_code">
              <a :class="{ active: Id == 'share' }">{{
                $t("text_refer_fri")
              }}</a>
            </li> -->
          </ul>
        </div>
      </div>
    </nav>
  </div>
</template>
<script>
import Cookies from "js-cookie";
import { mapGetters } from "vuex";
import Lang from "./Home/language.vue";
import balanceview from "./balance.vue";
import { main_logo } from "@/components/Img/img";

export default {
  name: "maintop2",
  data() {
    return {
      code: Cookies.get("code"),
      isMobile: this.$Utils.isMobile(),
      img_logo:
        localStorage.getItem("locale") == null ||
        localStorage.getItem("locale") == "" ||
        (localStorage.getItem("locale") != "en" &&
          localStorage.getItem("locale") != "cn")
          ? JSON.parse(JSON.stringify(main_logo))["en"]
          : JSON.parse(JSON.stringify(main_logo))[
              localStorage.getItem("locale")
            ],

      windowWidth: window.innerWidth,
      flag_resize: true,
    };
  },
  components: {
    Lang,
    balanceview,
  },
  computed: {
    ...mapGetters(["flag_nav", "flag_code"]),
    Id: function () {
      if (this.$route.params.id != null) return this.$route.params.id;
      else return "";
    },
    resize: function () {
      if (this.windowWidth > 1500) return true;
      else return false;
    },
  },
  methods: {
    open_login: function (id) {
      this.$store.commit(id, true);
    },
    show_nav: function () {
      this.$store.commit("flagnav", !this.flag_nav);
    },
  },
  created() {},
  watch: {},
  mounted() {
    // window.addEventListener("resize", function () {
    //   this.windowWidth = window.innerWidth;
    //   if (this.windowWidth > 1500) this.flag_resize = true;
    //   else this.flag_resize = false;
    // });
  },
};
</script>
