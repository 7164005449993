import { createI18n } from "vue-i18n";
import en from "./en-US.json";
import cn from "./cn.json";

var nav_lan = navigator.language.toLowerCase() ?? process.env.VUE_APP_LANG;
var locale = localStorage.getItem("locale") ?? process.env.VUE_APP_LANG;

if (locale != null && locale != "" && locale != "en") {
  sessionStorage.setItem("Json", JSON.stringify(locale));
} else {
  if (nav_lan == "zh" || nav_lan == "zh-cn") {
    locale = "cn";
  } else {
    locale = "en";
  }

  sessionStorage.setItem("Json", JSON.stringify(locale));
  localStorage.setItem("locale", locale);
}
const i18n = createI18n({
  legacy: false,
  locale: locale,
  fallbackLocale: process.env.VUE_APP_LANG,
  messages: {
    en: en,
    cn: cn,
  },
  // globalInjection: true
});

export default i18n;
