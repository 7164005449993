<template>
  <div>
    <div class="carousel-live-feed-holder">
      <div class="web-banner">
        <banner />
      </div>
      <div class="live-feed-holder">
        <div class="live-feed-primary-part-holder">
          <div class="live-feed-primary-part-text">
            <h2>{{ $t("text_recent_with") }}</h2>
          </div>
          <div class="live-feed-primary-part-numbers-holder">
            <div>
              <div
                class="live-feed-primary-part-number"
                v-for="l in tableData"
                :key="l"
              >
                <span>{{ l.num }}</span>
              </div>
            </div>
            <span></span>
            <div>
              <div
                class="live-feed-primary-part-number"
                v-for="l in tableData2"
                :key="l"
              >
                <span>{{ l.num }}</span>
              </div>
            </div>
          </div>
        </div>
        <dwrank :who_page="'main'" />
      </div>
    </div>
    <div>
      <!-- <game_page :m_show_id="'main_casino'" />
      <game_page :m_show_id="'main_slot'" />
      <game_page :m_show_id="'sport'" />
      <game_page :m_show_id="'poker'" />
      <game_page :m_show_id="'main_sport'" /> -->
      <homestock :m_show_id="'gamestock'" />
      <homestock :m_show_id="'sportstock'" />
      <homestock :m_show_id="'hotstock'" />
      <homestock :m_show_id="'marketstock'" />
      <homestock :m_show_id="'startupstock'" />
      <homestock :m_show_id="'etf'" />
      <immediatestock />
      <!-- <section class="show-more-section">
        <div class="show-more-section-content">
          <article class="page-article-container">
            <div class="page-article-content">
              <div v-html="$t('text_maintro1')"></div>
              <div v-html="$t('text_maintro2')"></div>
              <div v-show="open_intro">
                <p>
                  {{ $t("text_maintro3") }}
                  <a @click="open_login('isreg')" class="light_green">{{
                    $t("Sign_Up")
                  }}</a>
                  {{ $t("text_maintro4") }}
                </p>
                <h2>{{ $t("text_maintro5") }}</h2>
              </div>
            </div>
          </article>
        </div>
        <button
          class="btn"
          @click="open_intro = !open_intro"
          v-if="code == null || code == ''"
        >
          {{ !open_intro ? $t("text_sm") : $t("text_sl") }}
          <svg
            width="12"
            height="8"
            viewBox="0 0 12 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            :style="{ 'transform: rotate(180deg)': open_intro }"
          >
            <path
              d="M1.41 0.589966L6 5.16997L10.59 0.589966L12 1.99997L6 7.99997L0 1.99997L1.41 0.589966Z"
              fill="#F9F8F8"
            ></path>
          </svg>
        </button>
      </section> -->

      <qaview />
    </div>
  </div>
</template>

<script>
import Cookies from "js-cookie";
import banner from "./Home/Banner.vue";
import dwrank from "./dw_rank.vue";
import homestock from "./home_stock.vue";
import immediatestock from "./immediate_stock.vue";
import qaview from "./common_qa.vue";

export default {
  name: "main",
  data() {
    return {
      open_intro: false,
      code: Cookies.get("code"),
      tableData: [
        {
          num: Math.ceil(Math.random() * 8 + 1),
        },
        {
          num: Math.ceil(Math.random() * 8 + 1),
        },
        {
          num: Math.ceil(Math.random() * 8 + 1),
        },
      ],

      tableData2: [
        {
          num: Math.ceil(Math.random() * 8 + 1),
        },
        {
          num: Math.ceil(Math.random() * 8 + 1),
        },
        {
          num: Math.ceil(Math.random() * 8 + 1),
        },
      ],
    };
  },
  components: { banner, dwrank, homestock, immediatestock, qaview },
  computed: {},
  methods: {
    open_login: function (id) {
      this.$store.commit(id, true);
    },
  },
  created() {},
  watch: {},
  mounted() {},
};
</script>
